<template>
    <modal ref="modalmodalDuplicarHorario" titulo="Duplicar horario" adicional="Duplicar" tamano="modal-md" @adicional="duplicarHorario">
        <div v-loading="loading" class="container">
            <ValidationObserver tag="div">
                <ValidationProvider tag="div" class="row justify-content-center">
                    <p class="col-10 mb-3">Selecciona los días a los cuales se duplicará</p>
                    <div class="col-10">
                        <el-checkbox-group v-model="dias_nuevo" class="el-checkbox-negro">
                            <el-checkbox v-for="dia in dias_semana" :key="dia.id" class="w-100 mb-3 f-500" :label="dia.nombre" :disabled="dia.disabled" />
                        </el-checkbox-group>
                    </div>
                </ValidationProvider>
            </ValidationObserver>
        </div>
    </modal>
</template>
<script>
import Zonas from '~/services/zonas'
export default {
    data(){
        return{
            dias_semana: [
                {
                    id:1,
                    nombre:'Domingo',
                    disabled: false
                },
                {
                    id:2,
                    nombre:'Lunes',
                    disabled: false
                },
                {
                    id:3,
                    nombre:'Martes',
                    disabled: false
                },
                {
                    id:4,
                    nombre:'Miercoles',
                    disabled: false
                },
                {
                    id:5,
                    nombre:'Jueves',
                    disabled: false
                },
                {
                    id:6,
                    nombre:'Viernes',
                    disabled: false
                },
                {
                    id:7,
                    nombre:'Sábado',
                    disabled: false
                },
            ],  
            loading : false,
            id_zona : null,
            dia : null,
            dias_cerrado : [],
            dias_nuevo : [],
        }
    },
    computed:{
        dias_nuevos(){
            let dias = []
            this.dias_nuevo.forEach(element => {
                switch (element){
                case "Domingo":
                    dias.push(1)              
                    break;
                case "Lunes":
                    dias.push(2)              
                    break;
                case "Martes":
                    dias.push(3)              
                    break;
                case "Miercoles":
                    dias.push(4)              
                    break;
                case "Jueves":
                    dias.push(5)              
                    break;
                case "Viernes":
                    dias.push(6)              
                    break;
                case "Sábado":
                    dias.push(7)              
                    break;
                }
            });
            return dias
        }
    },
    watch:{
        dias_cerrado(dias){
            this.dias_semana.forEach(element => {
                if(dias.includes(element.id)){
                    element.disabled = true
                } 
            });
        },
        dia(dia){
            this.dias_semana.forEach(element => {
                if(dia == element.id){
                    element.disabled = true
                    return
                } 
            });
        },      
    },
    methods:{
        toggle(){
            this.$refs.modalmodalDuplicarHorario.toggle()
        },
        guardarValores(){
            this.$refs.modalmodalDuplicarHorario.toggle()
        },
        setDia(dia){
            this.dia = dia
        },
        setIdZona(id){
            this.id_zona = id
        },
        setDiasCerrado(dias){
            this.dias_cerrado = dias
        },
        async duplicarHorario(){
            try {
                this.loading = true
                let payload = {
                    id_zona : this.id_zona,
                    dia : this.dia,
                    dias_nuevo: this.dias_nuevos
                }
                const {data} = await Zonas.duplicarHorario(payload)
                this.loading = false
                if(data.success){
                    this.$notify({
                        title: 'Guardar Horario',
                        message: data.mensaje,
                        type: 'success'
                    });
                    this.reset()
                    this.$emit('horarioGuardado')
                    this.toggle()
                }
            } catch (e){
                this.loading = false
                console.log(e)
            }
        },
        reset(){
            this.id_zona = null
            this.dia = null
            this.dias_cerrado = []
            this.dias_nuevo = []
            this.dias_semana.forEach(element => {
                element.disabled = false
            });
        }
    }
}
</script>
<style lang="scss" scoped>

</style>